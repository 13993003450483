import React, { StrictMode } from 'react';
import './tailwind.css';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';





ReactDOM.render(
  <App />,
  document.querySelector('#root')
);